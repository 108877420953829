import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import img1 from '../IMAGES/success.jpg'
import img2 from '../IMAGES/money.jpg'
import '../STYLES/Home.css'
import { useNavigate } from "react-router-dom";

export function Home() {
    const navigate = useNavigate();
    return <div>
        <Navigation />
        <div className="main">
            {/*  */}
            <div className="row">
                <div className="separate-v p-md box md2 lg2 xl2 full-height">
                    <h1 className="page-main-title">Unlock the Funding Your Business
                        Deserves</h1>
                    <div>
                        <br />
                        <h3>Fuel Your Business Growth with the Right Commercial Loan</h3>
                        <p className="page-text">Whether you're looking to expand, purchase equipment, or secure working capital, we
                            specialize in connecting small businesses like yours with tailored commercial loan solutions. Let
                            us help you find the funding you need to turn your vision into reality.</p>
                    </div>
                </div>
                <div className="md2 lg1 xl2">
                    <img src={img1} className="full-width" />
                </div>
            </div>
            <h1 className="text-center p">Explore Our Loan Options</h1>
            <div className="home-banner separate-v p">
                <div className="row gap box">
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">SBA Loans</h1>
                        <p className="page-text">Low-interest, government-backed loans for small businesses.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Business Lines of Credit</h1>
                        <p className="page-text">Flexible funding to cover everyday expenses or unexpected costs.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Commercial Real Estate Loans</h1>
                        <p className="page-text">Purchase, refinance, or build your ideal business space.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Equipment Financing</h1>
                        <p className="page-text">Buy or lease essential equipment for your operations.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Working Capital Loans</h1>
                        <p className="page-text">Keep your cash flow healthy with short-term funding.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Merchant Cash Advances</h1>
                        <p className="page-text">Get a lump sum upfront based on your future sales.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Franchise Financing</h1>
                        <p className="page-text">Secure funding to start or expand your franchise.</p>
                    </div>
                    <div className="p-lg vertical gap-sm white bg-light box" style={{ backgroundColor: 'navy' }}>
                        <h1 className="page-topic">Bridge Loans</h1>
                        <p className="page-text">Short-term solutions to seize timely opportunities.</p>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className="bg-light p">
                <h1 className="text-center p">How It Works: Simple, Fast, and Personalized</h1>
                <br />
                <div className="p-lg vertical gap-sm bg-white box">
                    <h1 className="page-topic">1. Submit Your Information</h1>
                    <p className="page-text">Share some basic details about your business and funding needs using our easy online
                        form.</p>
                </div>
                <br />
                <div className="p-lg vertical gap-sm bg-white box">
                    <h1 className="page-topic">2. Speak with a Loan Specialist</h1>
                    <p className="page-text">Our team will reach out to discuss your goals and help identify the best loan options for
                        your business.</p>
                </div>
                <br />
                <div className="p-lg vertical gap-sm bg-white box">
                    <h1 className="page-topic">3. Apply with Confidence</h1>
                    <p className="page-text">Our team will reach out to discuss your goals and help identify the best loan options for
                        your business.</p>
                </div>
                <br />
                <div className="p-lg vertical gap-sm bg-white box">
                    <h1 className="page-topic">4. Receive Your Funds</h1>
                    <p className="page-text">Once approved, you’ll have the funding you need to grow your business in no time.</p>
                </div>
            </div>
            {/*  */}
            <div className="p">
                <h1 className="text-center p">Why choose us?</h1>
                <br />
                <div className="row gap">
                    <div className="md2">
                        <div className="p-lg vertical gap-sm bg-light">
                            <h1 className="page-topic orange">Expert Guidance</h1>
                            <p className="page-text">Over [X years] of experience helping small businesses secure
                                funding.</p>
                        </div>
                        <div className="p-lg vertical gap-sm bg-light">
                            <h1 className="page-topic orange">Tailored Solutions</h1>
                            <p className="page-text">We match you with the loan that fits your business goals.</p>
                        </div>
                        <div className="p-lg vertical gap-sm bg-light">
                            <h1 className="page-topic orange">Fast Approvals</h1>
                            <p className="page-text">Streamlined processes so you can get funded quickly.</p>
                        </div>
                        <div className="p-lg vertical gap-sm bg-light">
                            <h1 className="page-topic orange">Dedicated Support</h1>
                            <p className="page-text">Personalized assistance every step of the way.</p>
                        </div>
                    </div>
                    <div className="md2 lg1 xl2">
                        <img src={img2} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                    </div>
                </div>
            </div>
            <br />
            <div className="p white" style={{ backgroundColor: 'navy' }}>
                <h1 className="p">Don’t Wait to Build Your Future</h1>
                <div className="p">
                    <p className="text-md">Your business deserves the right financial tools to succeed. Submit your information today, and
                        let us help you unlock your potential!</p>
                </div>
                <div className="horizontal-center p">
                    <div onClick={() => {
                        navigate('/inquiry')
                    }} className="fit-width bg-white black full-radius p"><p className="m-h">Submit Your Info</p></div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}