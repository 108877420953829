import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import '../STYLES/Info.css'
import { IoChatbox } from "react-icons/io5";
import { Clickable } from "../UTILITIES/Clickable";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { gemini_SendChat, gemini_StartChat } from "../gemini";
import { randomString } from "../functions";
import { Loading } from '../UTILITIES/Loading'
import { Accordion } from "../UTILITIES/Accordion";
import { TbChevronDown } from "react-icons/tb";
import { FaLink } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function Info() {
    const navigate = useNavigate();
    const [showChat, setShowChat] = useState(false);
    const [chat, setChat] = useState(null)
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false);
    const faqs = [
        {
            question: 'How long does the approval process take?',
            answer: 'Most loans are approved within 24-72 hours, with funding available shortly after.',
            link: ''
        },
        {
            question: 'Can I qualify with bad credit?',
            answer: 'Yes! We work with a network of lenders, some of whom specialize in assisting borrowers with less-than-perfect credit.',
            link: ''
        },
        {
            question: 'How much can I borrow?',
            answer: 'Loan amounts range from $10,000 to $5,000,000+, depending on your business’s financials and the loan product.',
            link: ''
        }
    ];

    async function onSendMessage() {

        const text = document.querySelector("#tbText").value;
        document.querySelector('#tbText').value = "";

        setMessages((prev) => [...prev, {
            id: randomString(25),
            message: text,
            role: 'me',
            date: new Date().getTime()
        }])
        await gemini_SendChat(chat, text, (response) => {
            setMessages((prev) => [...prev, {
                id: randomString(25),
                message: response.response.text(),
                role: 'gemini',
                date: new Date().getTime()
            }])
        })
    }

    async function onStartNewChat() {
        // 

    }

    async function onStart() {
        setLoading(true);
        await gemini_StartChat(setChat);
        setLoading(false);
    }

    useEffect(() => {
        onStart()
    }, [])

    // 
    return <div>
        {loading && <Loading />}
        <Navigation />
        <div className="main relative">
            {/*  */}

            <div className="row">
                <div className="md2 lg2 p box">
                    <h1 className="text-xl">Discover How Easy It Is to Get Funded</h1>
                    <br />
                    <div>
                        <h3>The Benefits of Partnering with Us</h3>
                        <p className="orange">We know that securing a commercial loan can feel overwhelming. That’s why we make the
                            process simple, transparent, and stress-free. Here’s what you can expect:</p>
                    </div>
                </div>
            </div>
            <div className="row p gap box">
                <div className="p bg-light box">
                    <h3>Flexible Loan Terms</h3>
                    <p>Customized repayment options to suit your business’s cash flow.</p>
                </div>
                <div className="p bg-light box">
                    <h3>Competitive Rates</h3>
                    <p>Access to lenders offering the most favorable terms.</p>
                </div>
                <div className="p bg-light box">
                    <h3>Comprehensive Loan Options</h3>
                    <p>No matter your industry or goal, we have you covered.</p>
                </div>
                <div className="p bg-light box">
                    <h3>Small Business Focus</h3>
                    <p>We understand the challenges and opportunities that small
                        businesses face.</p>
                </div>
            </div>
            <div className="poppins bg-light p box">
                <h1>What Do I Need to Qualify for a Loan?</h1>
                <br />
                <h3>While requirements vary depending on the loan type, here are some common qualifications:</h3>
                <ul>
                    <li>Business operating for at least 6-12 months (for most loans).</li>
                    <li>A reasonable credit score (minimums depend on the loan product).</li>
                    <li>Clear purpose for the loan (e.g., equipment, real estate, or working capital).</li>
                    <li>Financial documents such as tax returns, profit and loss statements, or bank statements.</li>
                </ul>
            </div>
            <br />
            <hr />
            <br />
            <div className="p-lg">
                <h1 className="center">Frequently Asked Questions</h1>
            </div>
            <div className="row p-lg box gap">
                {
                    faqs.map((faq, i) => {
                        return <div key={i} className="md2 lg3">
                            <Accordion
                                classes={'p box shadow-light radius-sm'}
                                top={
                                    <div className="separate-h vertical-center">
                                        <p className="text-md bold">{faq.question}</p>
                                        <TbChevronDown size={30} />
                                    </div>
                                } bottom={
                                    <div className="box p-v">
                                        <p className="space box">{faq.answer}</p>
                                        {
                                            faq.link !== undefined && faq.link !== "" &&
                                            <div>
                                                <br />
                                                <Clickable onPress={() => {
                                                    window.open(faq.link, '_blank')
                                                }}>
                                                    <div className="flex-row gap-sm vertical-center fit-width">
                                                        <p className="orange space">learn more</p>
                                                        <FaLink className="orange" size={13} />
                                                    </div>
                                                </Clickable>
                                            </div>
                                        }
                                    </div>
                                } />
                        </div>
                    })
                }


            </div>
            <br />
            <div className="p white" style={{ backgroundColor: 'navy' }}>
                <h1 className="p">Take the First Step Today</h1>
                <div className="p">
                    <p className="text-md">Don’t let a lack of funding hold your business back. Fill out our short form now, and one of our
                        loan specialists will reach out to help you find the best loan options for your needs.</p>
                </div>
                <div className="horizontal-center p">
                    <div onClick={() => {
                        navigate('/inquiry')
                    }} className="fit-width bg-white black full-radius p"><p className="m-h">Submit Your Info</p></div>
                </div>
            </div>

            {/* CHAT BUTTOn */}
            <div className="fixed right bottom p">
                <Clickable onPress={() => {
                    setShowChat(true)
                }}>
                    <div className="chat-btn vertical-center">
                        <IoChatbox color="white" size={25} />
                    </div>
                </Clickable>
            </div>
        </div>


        {/* CHAT */}
        {
            showChat &&
            <div className="chat-box fixed bottom right separate-v box">
                <div className="bg-black white p separate-h vertical-center">
                    <p className="text-sm">Virtual Chat</p>
                    <Clickable onPress={() => { setShowChat(false) }}><IoMdClose color="white" size={25} /></Clickable>
                </div>
                <div className="chat-messages full-height scroll-y p-xs">
                    {
                        messages.map((mess, i) => {
                            return <div key={i} className={`${mess.role === "me" ? "bg-light-gray" : "bg-orange white   "} m-sm p-sm radius-sm`}>
                                <p className="text-xs">{mess.message}</p>
                            </div>
                        })
                    }
                </div>
                {/* TEXTFIELD */}
                <div className="box flex-row vertical-center">
                    <input id="tbText" type="text" placeholder="Type message here..." className="full-width p box no-border" />
                    <div className="p-h">
                        <Clickable onPress={() => { onSendMessage() }}>
                            <IoSend size={22} />
                        </Clickable>
                    </div>
                </div>
            </div>
        }
        <Footer />
    </div>
}